<template>
  <div>
    <GenericTable
      ref="lista"
      name="tipo de desinfecção"
      title="desinfecções"
      add-route="tipoDesinfeccaoAdicionar"
      edit-route="tipoDesinfeccaoEditar"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="tipoDesinfeccaoRoute"
      :permissionsToWrite="['rw_esterilizacao']"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      tipoDesinfeccaoRoute: 'tipoDesinfeccao',
    };
  },
};
</script>
